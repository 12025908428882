import { useEffect } from 'react'
import {
  DesktopModalWrapper,
  Container
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { sleep } from '@smu-chile/pkg-unimarc-hooks'
import { SimilarModalBackbone } from '../SimilarModalBackbone'
import { ICartItemIssue } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { SimilarModalBody } from '../SimilarModalBody'
import { IProductsSearchResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsSearch'
import { SimilarModalButton } from '../SimilarModalButton'
import styles from '../../MyUsualsAndFavoritesWrapper/MyUsualsAndFavoritesWrapper.module.css'

export interface SimilarModalGlobalProps {
  isLoading: boolean
  isLoadingOrderForm: boolean
  orderItem: ICartItemIssue
  similarProducts: IProductsSearchResponse
  handleClose: () => void
}

export const SimilarModalDesktop = ({
  isLoading,
  isLoadingOrderForm,
  orderItem,
  similarProducts,
  handleClose
}: SimilarModalGlobalProps): React.ReactElement => {
  const modal = document.getElementById('similarModal__container')

  const handleClick = () => {
    return
  }

  const onClose = async () => {
    if (modal) {
      modal.classList.remove(styles.openModal)
      modal.classList.add(styles.closeModal)
      await sleep(200)
    }
    handleClose()
  }

  useEffect(() => {
    if (modal) {
      modal.classList.add(styles.openModal)
      modal.classList.remove(styles.closeModal)
    }
  }, [modal])

  useEffect(() => {
    const interval = setInterval(() => {
      const modal = document.getElementById('similarModal__container')
      if (modal) {
        modal.classList.add(styles.openModal)
        modal.classList.remove(styles.closeModal)
        clearInterval(interval)
      }
    }, 50) // Check every 50ms

    return () => {
      return clearInterval(interval)
    }
  }, [isLoading])

  return (
    <DesktopModalWrapper
      blockId={getBemId('similarModal', 'header')}
      bodyChildrenProps={{
        maxHeight: 'none',
        minHeight: 'calc(100vh - 161px)',
        overflow: 'auto',
        padding: '16px'
      }}
      borderEdge='none'
      footerChildrenProps={{
        boxShadow: '0',
        default: false,
        children: (
          <Container
            customClassName={styles.customBorderTop}
            justifyContent='center'
            padding='16px 32px'
          >
            <SimilarModalButton
              handleOnClick={handleClick}
              isLoadingOrderForm={isLoadingOrderForm}
              minWidth='406px'
            />
          </Container>
        ),
        height: 72,
        padding: '0',
        bottom: '0'
      }}
      headerCloseIcon={{
        name: 'CloseThin',
        customSize: 20,
        default: true
      }}
      headerContainerProps={{
        minHeight: '68px'
      }}
      headerCustomClass={styles.customHeader}
      headerTitle='Reemplazar producto'
      headerTitleProps={{
        fontWeight: 'semibold',
        default: true
      }}
      modalConfigsProps={{
        containerId: getBemId('similarModal', 'container'),
        customContainerClassName: styles.modalContainer,
        isOpen: true,
        minHeight: '100vh',
        maxWidth: '656px',
        isWindowBlocked: true,
        toggle: onClose,
        toggleOutside: onClose
      }}
      styleProps={{
        borderRadius: getGlobalStyle('--border-radius-none'),
        customHeightModalContent: '100%'
      }}
    >
      {isLoading && !similarProducts && <SimilarModalBackbone />}
      {!isLoading && (
        <SimilarModalBody
          isLoadingOrderForm={isLoadingOrderForm}
          orderItem={orderItem}
          similarProducts={similarProducts}
        />
      )}
    </DesktopModalWrapper>
  )
}
