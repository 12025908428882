import React from 'react'
import { Container, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  isValidArrayWithData,
  useContentful
} from '@smu-chile/pkg-unimarc-hooks'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { IProductsSearchResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsSearch'
import { ShelfWrapper } from './ShelfWrapper'
import { promotionsOfShelves } from 'shared/helpers/promotionsOfShelves'
import {
  CAMPAING_APP_CHIPS_UNIMARC,
  REACT_QUERY_GENERAL
} from 'shared/utils/constants'

interface SimilarModalShelvesProps {
  data: IProductsSearchResponse
  isLoadingOrderForm: boolean
  isMobile?: boolean
}

export const SimilarModalShelves = ({
  data,
  isLoadingOrderForm,
  isMobile
}: SimilarModalShelvesProps): React.ReactElement => {
  const resultPromotions = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE,
      'sys.id[in]': CAMPAING_APP_CHIPS_UNIMARC
    },
    reactQuery: REACT_QUERY_GENERAL
  })

  // clean the object of promotions
  const dataPromotions =
    promotionsOfShelves({ data: resultPromotions?.data }) ?? []

  return (
    <Container
      gap={isMobile ? 0 : 8}
      id={getBemId('similarModalBody', 'shelves')}
      isWrap
    >
      {isValidArrayWithData(data?.availableProducts) &&
        data.availableProducts.map((product, index) => {
          return (
            <React.Fragment key={product?.item?.itemId}>
              <ShelfWrapper
                dataPromotions={dataPromotions}
                indexItem={index}
                isLoadingOrderForm={isLoadingOrderForm}
                isMobile={isMobile}
                product={product}
              />
            </React.Fragment>
          )
        })}
      <Spacer.Horizontal customSize={16} />
    </Container>
  )
}
