import {
  MobileModalWrapper,
  Container
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from '../../MyUsualsAndFavoritesWrapper/MyUsualsAndFavoritesWrapper.module.css'
import { SimilarModalBackbone } from '../SimilarModalBackbone'
import { SimilarModalGlobalProps } from '../SimilarModalDesktop'
import { SimilarModalButton } from '../SimilarModalButton'
import { SimilarModalBody } from '../SimilarModalBody'

export const SimilarModalMobile = ({
  isLoading,
  isLoadingOrderForm,
  orderItem,
  similarProducts,
  handleClose
}: SimilarModalGlobalProps): React.ReactElement => {
  const handleClick = () => {
    return
  }
  return (
    <MobileModalWrapper
      blockId={getBemId('myUsualsAndFavoritesModal', 'container')}
      body={
        <>
          {isLoading && <SimilarModalBackbone />}
          {!isLoading && (
            <SimilarModalBody
              isLoadingOrderForm={isLoadingOrderForm}
              isMobile={true}
              orderItem={orderItem}
              similarProducts={similarProducts}
            />
          )}
        </>
      }
      bodyContainerProps={{
        padding: isLoading ? '16px 12px' : '8px 12px'
      }}
      customFooter={
        <Container
          backgroundColor={getGlobalStyle('--color-base-white')}
          customClassName={styles.customBorderTop}
          justifyContent='center'
          padding='16px'
          zIndex={getGlobalStyle('--z-index-5')}
        >
          <SimilarModalButton
            handleOnClick={handleClick}
            isLoadingOrderForm={isLoadingOrderForm}
            minWidth='100%'
          />
        </Container>
      }
      dragProps={{
        draggButton: false
      }}
      footerContainerProps={{
        padding: '0'
      }}
      hasShadow={false}
      headerCloseIconProps={{
        name: 'CloseThin',
        customSize: 20
      }}
      headerProps={{
        padding: '0 16px 20px',
        minHeight: '36px',
        customClassName: styles.customHeader
      }}
      headerTitle='Reemplazar producto'
      hideHeaderDivider={false}
      iconColor={getGlobalStyle('--color-base-black')}
      iconSize={20}
      isEnableButton
      modalConfigsProps={{
        toggle: handleClose,
        toggleOutside: handleClose,
        isAutoHeight: true,
        hiddenX: true,
        stickyBottom: true,
        fullScreenSticky: true,
        isOpen: true,
        marginFullScreen: '1vh 0 0',
        openAnimation: true,
        isWindowBlocked: true
      }}
      onClose={handleClose}
      styleProps={{
        borderRadius: `${getGlobalStyle('--border-radius-md')} ${getGlobalStyle(
          '--border-radius-md'
        )} 0 0`,
        height: '100%',
        padding: '0'
      }}
    />
  )
}
