import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  currentOrderformInfoTag,
  postValidateSc,
  useEvents,
  useMobile,
  useOrderForm
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Button,
  ButtonStatus,
  Container,
  Row
} from '@smu-chile/pkg-unimarc-components'
import style from './ButtonGoToPay.module.css'
import generalStyle from '../../Body/Body.module.css'

interface ButtonGoToPayProps {
  customStyle?: boolean
  isLoading: boolean
  isLoadingOrderForm?: boolean
  onClick?: () => void
}

const ButtonGoToPay = ({
  customStyle = false,
  isLoading,
  isLoadingOrderForm = false,
  onClick
}: ButtonGoToPayProps): React.ReactElement => {
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('initial')
  const [loadingButton, setLoadingButton] = useState(isLoading)
  const { data } = useOrderForm()
  const { isMobile } = useMobile()
  const addressValidator =
    !data?.data?.selectedAddresses ||
    data?.data?.selectedAddresses?.country !== 'CHL'
  const site = 'Unimarc'

  const handleOnClick = async () => {
    setLoadingButton(true)
    const responseValidateSc = await postValidateSc({
      geoCoordinates: data?.data?.selectedAddresses?.geoCoordinates
    })

    currentOrderformInfoTag({
      event: 'begin_checkout',
      site,
      session_store: responseValidateSc?.data?.data?.id
    })

    onClick()
  }

  useEvents({
    eventType: 'shipmentWindowChanged',
    callBack: () => {
      setLoadingButton(false)
    }
  })

  useEffect(() => {
    if (isLoadingOrderForm) {
      setButtonStatus('loading')
    } else {
      if (addressValidator) {
        setButtonStatus('disabled')
      } else {
        setButtonStatus('initial')
      }
    }
  }, [isLoadingOrderForm, data?.data])

  useEffect(() => {
    setLoadingButton(isLoading)
  }, [isLoading])

  return (
    <Container isWrap>
      <Row justifyContent='center'>
        <Button
          actionType='button'
          borderRadius={getGlobalStyle('--border-radius-xl')}
          customClassName={classNames(generalStyle.focusBtn__mobile, {
            [style['button__loading']]: customStyle
          })}
          fontWeight={getGlobalStyle('--font-weight-medium')}
          height={isMobile ? '40px' : '44px'}
          id='GA__button--finishOrder'
          isLoading={loadingButton}
          label='Continuar'
          onClick={handleOnClick}
          pointLoaderBigger={true}
          size={isMobile ? 'lg' : 'xl'}
          status={buttonStatus}
          type='primary-gray'
          width='100%'
        />
      </Row>
    </Container>
  )
}

export default ButtonGoToPay
