import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { trigger, useProductsSearch } from '@smu-chile/pkg-unimarc-hooks'
import { SimilarModalDesktop } from './SimilarModalDesktop'
import { SimilarModalMobile } from './SimilarModalMobile/intex'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { ICartItemIssue } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'

interface SimilarModalProps {
  isLoadingOrderForm: boolean
  lookReplacement: string
  orderItem: ICartItemIssue
}

export const SimilarModal = ({
  isLoadingOrderForm,
  lookReplacement,
  orderItem
}: SimilarModalProps): React.ReactElement => {
  const { data, isLoading } = useProductsSearch({
    categories: lookReplacement,
    from: '0',
    to: '19',
    reactQuery: {
      queryKey: lookReplacement,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })

  const handleClose = () => {
    trigger({
      eventType: 'showSimilarModal',
      data: { show: false, orderItem: null }
    })
  }

  return (
    <>
      <BigScreen>
        <SimilarModalDesktop
          handleClose={handleClose}
          isLoading={isLoading}
          isLoadingOrderForm={isLoadingOrderForm}
          orderItem={orderItem}
          similarProducts={data}
        />
      </BigScreen>
      <SmallScreen>
        <SimilarModalMobile
          handleClose={handleClose}
          isLoading={isLoading}
          isLoadingOrderForm={isLoadingOrderForm}
          orderItem={orderItem}
          similarProducts={data}
        />
      </SmallScreen>
    </>
  )
}
