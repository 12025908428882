import { Column, Container, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ICartItemIssue } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { IProductsSearchResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsSearch'
import { ProductDetails } from 'components/Body/ProductsTotalizers/Products/Shelves/Shelf/ProductDetails'
import { SimilarModalShelves } from '../SimilarModalShelves'

interface SimilarModalBodyProps {
  isLoadingOrderForm: boolean
  isMobile?: boolean
  orderItem: ICartItemIssue
  similarProducts: IProductsSearchResponse
}

export const SimilarModalBody = ({
  isLoadingOrderForm,
  isMobile,
  orderItem,
  similarProducts
}: SimilarModalBodyProps): React.ReactElement => {
  return (
    <Container>
      <Column>
        <Row
          border={`0.6px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          padding={isMobile ? '8px' : '12px 16px'}
        >
          <ProductDetails
            img={orderItem?.image}
            isNotAvailableProduct={true}
            isSimilarModal={true}
            name={orderItem?.name}
            orderItem={orderItem}
          />
        </Row>
        <SimilarModalShelves
          data={similarProducts}
          isLoadingOrderForm={isLoadingOrderForm}
          isMobile={isMobile}
        />
      </Column>
    </Container>
  )
}
