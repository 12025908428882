import {
  Backbone,
  BackboneShelves,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

interface SimilarModalBackboneProps {
  isMobile?: boolean
}

export const SimilarModalBackbone = ({
  isMobile = false
}: SimilarModalBackboneProps) => {
  return (
    <Container>
      <Column>
        <Row>
          <Backbone
            borderRadius={getGlobalStyle('--border-radius-md')}
            height={104}
            width={100}
          />
        </Row>
        <Spacer.Horizontal customSize={16} />
        <Row>
          <Backbone
            borderRadius={getGlobalStyle('--border-radius-lg')}
            height={34}
            width={100}
          />
        </Row>
        <Spacer.Horizontal customSize={30} />
        <BackboneShelves
          noBackground={true}
          shelvesForRow={isMobile ? 2 : 3}
          shelvesQuantityDesktop={9}
          shelvesQuantityMobile={6}
        />
      </Column>
    </Container>
  )
}
