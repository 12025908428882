/**
 * Formats a given string into a URL-friendly format by:
 * - Removing leading and trailing whitespace
 * - Converting to lowercase
 * - Normalizing and removing accented characters
 * - Removing commas
 * - Replacing spaces with dashes
 * - Removing unnecessary slashes and hyphen/slash combinations
 *
 * @param {string} input - The input string to be formatted.
 * @returns {string} The formatted string.
 *
 * @example
 * const formatted = formatLookReplacement(""/Desayuno y Dulces/Café/Sabores y Cremas/"")
 * console.log(formatted)
 * Output: "desayuno-y-dulces/cafe/sabores-y-cremas"
 */
export const formatLookReplacement = (input: string): string => {
  if (typeof input !== 'string') {
    return ''
  }

  return input
    .trim() // Removes leading and trailing whitespace
    .toLowerCase() // Convert everything to lowercase
    .normalize('NFD') // Normalizes accented characters
    .replace(/[\u0300-\u036f]/g, '') // Removes accent marks
    .replace(/,/g, '') // Removes commas
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/\/+/g, '/') // Handles multiple consecutive slashes
    .replace(/^\//, '') // Remove leading slash if present
    .replace(/\/$/, '') // Remove trailing slash if present
}
